import { useState, useEffect, useMemo } from "react";
import { useRouter } from "next/router";
//Components
import { Typography } from "../../components";
//Recoil
import { useAtoms } from "../../recoil/hooks";
//Api
import { nextAPI } from "../../api/axiosAPI";
import EastIcon from '@mui/icons-material/East';
//Locale
import useTranslation from "next-translate/useTranslation";
import { isMobile } from 'react-device-detect';
import Spinner from "@components/Spinner";

const HomeScreen = () => {
    const router = useRouter();
    const { state } = useAtoms();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [onClient, setOnClient] = useState(false);
    const pageTranslationCode = `home${process.env.LOCALES_SUFFIX}`;

    const routeToMain = () => {
        setLoading(true);
        router.push("/main_management", null, { shallow: true });
    }

    const routeToOperations = () => {
        setLoading(true);
        router.push("/op_management", null, { shallow: true });
    }

    const routeToProfile = () => {
        setLoading(true);
        router.push("/profile", null, { shallow: true });
    }

    const routeToBuy = (category) => {
        setLoading(true);
        router.push(`/buy/${category}`, null, { shallow: true });
    }

    const routeToHistory = () => {
        setLoading(true);
        router.push("/purchase", null, { shallow: true });
    }

    /*  The visibility of the menu items is controlled by the following env defined object:
        MENU_VISIBILITY_PER_ROLE={"exhibitor":{"invite":true,"operational":true},"school":{"invite":true,"operational":false},"agency":{"invite":true,"operational":false},"office":{"invite":true,"operational":true},"partner":{"invite":true,"operational":false}}
    */
   const handleElementVisibility = (role, elementDomain) => {
       const MENU_VISIBILITY_PER_ROLE = JSON.parse(process.env.MENU_VISIBILITY_PER_ROLE);
        if (MENU_VISIBILITY_PER_ROLE[role]) {
            return MENU_VISIBILITY_PER_ROLE[role][elementDomain];
        }
        return false;
    }

    const handleInvoiceVisibility = () => {
        return state.invoiceAvailable
    }

   useEffect(() => {
       if (isMobile) {
           router.push("/main_management", null, { shallow: true });
           return;
        }
        setOnClient(true);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    const userDisplayName = useMemo(() => {
        return state.customer?.firstname || "";
    }, [state.customer, onClient]);
 
    const operationsEnabled = useMemo(() => {
        return handleElementVisibility(state.customer?.customer_type, "operational") && onClient;
    }, [state.customer, onClient]);
 
    const invitationsEnabled = useMemo(() => {
        return handleElementVisibility(state.customer?.customer_type, "invite") && onClient;
    }, [state.customer, onClient]);

    const invoicesEnabled = useMemo(() => {
        return handleInvoiceVisibility(state.customer?.customer_type) && onClient;
    }, [state.customer, onClient]);

    const spinner = loading && <Spinner />;

    return (
        <>
            {spinner}
            <div className={`flex flex-col p-10 mx-auto space-y-10 ${state.sidebarShowing ? 'md:w-4/5' : 'md:w-11/12'}`}>
                <div className="flex items-center justify-start pl-10 pr-8 pb-4 w-full h-40 rounded-lg" style={{
                    background: "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #C2C9D1"
                }}>
                    {onClient && (
                        <h1 className="text-6xl">
                            {t(`${pageTranslationCode}:banner.welcome`, { user: userDisplayName })}
                        </h1>
                    )}
                </div>

                <div className="flex items-center justify-between">
                    <div className="flex space-x-4">
                        {(invitationsEnabled && process.env.IS_DIREZIONE !== "true") && (
                        <button className="flex items-center justify-between p-3 border border-app-defaultColor rounded-lg hover:bg-red-200" onClick={() => routeToBuy("g")}>
                            <h3 className="text-app-defaultColor text-normal font-semibold">
                                {t(`${pageTranslationCode}:header.buy_invitations`)}
                            </h3>
                            <EastIcon
                                className="text-app-defaultColor ml-4"
                            />
                        </button>
                    )}
                        {(operationsEnabled && process.env.IS_DIREZIONE !== "true") && (
                        <button className="flex items-center justify-between p-3 border border-app-defaultColor rounded-lg hover:bg-red-200" onClick={() => routeToBuy("o")}>
                            <h3 className="text-app-defaultColor text-normal font-semibold">
                                {t(`${pageTranslationCode}:header.buy_passes`)}
                            </h3>
                            <EastIcon
                                className="text-app-defaultColor ml-4"
                            />
                        </button>
                    )}
                    </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                    {invitationsEnabled && (
                        <button className="flex items-center justify-between py-6 px-10 border border-app-grayDF rounded-lg hover:bg-red-200" onClick={routeToMain}>
                            <div className="flex flex-col">
                                <h3 className="text-left text-xl font-semibold">
                                    {t(`${pageTranslationCode}:body.handle_invitations_button.title`)}
                                </h3>
                                <h4 className="text-left text-normal">
                                    {t(`${pageTranslationCode}:body.handle_invitations_button.description`)}
                                </h4>
                            </div>
                            <div
                                className="border border-app-defaultColor p-4 rounded-lg ml-10 transform translateZ(0)"
                            >
                                <EastIcon
                                    fontSize="medium"
                                    className="text-app-defaultColor"
                                />
                            </div>
                        </button>
                    )}
                    {operationsEnabled && (
                        <button className="flex items-center justify-between py-6 px-10 border border-app-grayDF rounded-lg hover:bg-red-200" onClick={routeToOperations}>
                            <div className="flex flex-col">
                                <h3 className="text-left text-xl font-semibold">
                                    {t(`${pageTranslationCode}:body.handle_passes_button.title`)}
                                </h3>
                                <h4 className="text-left text-normal">
                                    {t(`${pageTranslationCode}:body.handle_passes_button.description`)}
                                </h4>
                            </div>
                            <div
                                className="border border-app-defaultColor p-4 rounded-lg ml-10 transform translateZ(0)"
                            >
                                <EastIcon
                                    fontSize="medium"
                                    className="text-app-defaultColor"
                                />
                            </div>
                        </button>
                    )}
                    <button className="flex items-center justify-between py-6 px-10 border border-app-grayDF rounded-lg hover:bg-red-200" onClick={routeToHistory}>
                        <div className="flex flex-col">
                            <h3 className="text-left text-xl font-semibold">
                                {t(`${pageTranslationCode}:body.orders_history_button.title`)}
                            </h3>
                            <h4 className="text-left text-normal">
                                {t(`${pageTranslationCode}:body.orders_history_button.description`)}
                            </h4>
                        </div>
                        <div
                            className="border border-app-defaultColor p-4 rounded-lg ml-10 transform translateZ(0)"
                        >
                            <EastIcon
                                fontSize="medium"
                                className="text-app-defaultColor"
                            />
                        </div>
                    </button>
                    {invoicesEnabled && (<button className="flex items-center justify-between py-6 px-10 border border-app-grayDF rounded-lg hover:bg-red-200" onClick={routeToProfile}>
                            <div className="flex flex-col">
                                <h3 className="text-left text-xl font-semibold">
                                    {t(`${pageTranslationCode}:body.notify_anomaly_button.title`)}
                                </h3>
                                <h4 className="text-left text-normal">
                                    {t(`${pageTranslationCode}:body.notify_anomaly_button.description`)}
                                </h4>
                            </div>
                            <div
                                className="border border-app-defaultColor p-4 rounded-lg ml-10 transform translateZ(0)"
                            >
                                <EastIcon
                                    fontSize="medium"
                                    className="text-app-defaultColor"
                                />
                            </div>
                        </button>)
                    }
                </div>
            </div>
        </>
    );
};

export default HomeScreen;
