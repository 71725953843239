
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Head from "../components/Head/Head";
import { getSession } from "next-auth/react";
import { nextAPI } from "../api/axiosAPI";
import { isMobile } from 'react-device-detect';
import { useEffect } from "react";
import HomeScreen from "src/screens/HomeScreen";
import { useRouter } from "next/router";
const Home = () => {
    const router = useRouter();
    useEffect(() => {
        if (isMobile) {
            router.push("/main_management", undefined, { shallow: true });
        }
    }, [isMobile]);
    return (<>
      <Head title="Home"/>
      {isMobile ? null : <HomeScreen />}
    </>);
};
const getServerSideProps = async (context) => {
    const session = await getSession(context);
    const inject = {
        props: {},
    };
    if (session) {
        try {
            await nextAPI.post("/login");
            inject.props = {
                session,
            };
        }
        catch (error) {
            console.error(error);
        }
    }
    return inject;
};
export default Home;

    async function __Next_Translate__getServerSideProps__18ee2071cf5__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__18ee2071cf5__ as getServerSideProps }
  